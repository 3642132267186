import { memo } from "react";
import AppInstallBtn from "../Buttons/AppInstallBtn";
import DoubtnutLogo from "../SVG/DoubtnutLogo";
import DoubtnutLogoName from "../SVG/DoubtnutLogoName";
import RightIcons from "./RightIcons";
import dynamic from "next/dynamic";

const Navbar = dynamic(() => import("./Navbar"), {
  ssr: false
});

const Header = () => {
  return <header className="w-full flex flex-col z-30 fill-primary">
    <div className="flex justify-between h-14 lg:h-16 pl-3 pr-1">
      <div className="flex items-center gap-4 lg:gap-12 grow">
        {/* <LinkWrapper href={"/"} className="flex align-center" id="hd-logo"> */}
        <a href="/" className="flex items-center" id="hd-logo">
          <DoubtnutLogo className="fill-accent h-10 py-2 sm:hidden" />
          <DoubtnutLogoName className="fill-accent h-12 py-2 hidden sm:block" />
        </a>
        <AppInstallBtn />
        <Navbar />
      </div>
      <RightIcons />
    </div>
  </header>
};


export default memo(Header);
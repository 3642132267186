import { useRouter } from "next/router";
import CommonLayout from "./Common";
import NoFooterLayout from "./NoFooter";
import MinimalHeaderLayout from "./MinimalHeader";
import ProfileLayout from "./Profile";
import { ProfileProvider } from "@/providers/profile";
import AllenLogo from "@/components/SVG/AllenLogo";
import NoHeaderFooterLayout from "./NoHeaderFooter";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setStudentId } from "@/store/slices/auth";
import { getStudentIdLS } from "@/utils/auth";

const AppLayout = (props: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const webStudentId = getStudentIdLS();
    if(!webStudentId){
      //@ts-ignore
      dispatch(setStudentId())
    }
  }, [])

  if (router.asPath.startsWith("/allen-payment")) {
    return <NoHeaderFooterLayout>
      <div className="flex justify-center mb-6 p-4 bg-[#0f1825]">
        <AllenLogo className="h-8" />
      </div>
      {props.children}
    </NoHeaderFooterLayout>;
  }

  switch (router.pathname) {
    case "/allen-offline-event":
    case "/allen-offline-event-pdf":
    case "/allen-offline-event-feedback":
    case "/allen-distributor-up":
    case "/allen-aaa":
      return <NoHeaderFooterLayout>
        {props.children}
      </NoHeaderFooterLayout>;
    case "/checkout":
    case "/scholarship":
    case "/scholarship/[testId]/result":
    case "/testseries":
    case "/testseries/[testId]/result":
    case "/quiz/[groupId]":
    case "/counselling":
    case "/pro":
      return <NoFooterLayout>{props.children}</NoFooterLayout>;
    case "/scholarship/[testId]":
    case "/testseries/[testId]":
    case "/pdf-viewer":
      return <MinimalHeaderLayout>{props.children}</MinimalHeaderLayout>
    case "/change-pin":
    case "/profile":
    case "/saved-videos":
      return <CommonLayout>
        <ProfileProvider>
          <ProfileLayout>
            {props.children}
          </ProfileLayout>
        </ProfileProvider>
      </CommonLayout>
    default:
      return <CommonLayout>
        {props.children}
      </CommonLayout>;
  }
};

export default AppLayout;

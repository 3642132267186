import LinkWrapper from "@/components/LinkWrapper";
import DoubtnutLogoName from "@/components/SVG/DoubtnutLogoName";
import { inter } from "@/helpers/font";

const MinimalHeaderLayout = (props: { children: React.ReactNode }) => {
  return (
    <div id="base-layout" className={`${inter.variable} font-sans`}>
      <header className="h-14 lg:h-16 flex pl-3 pr-1 z-30 fill-primary">
        <LinkWrapper href="/" className="flex items-center" id="hd-logo">
          <DoubtnutLogoName className="fill-primary h-12 py-2 block" />
        </LinkWrapper>
      </header>
      <hr/>

      {props.children}
    </div>
  );
};

export default MinimalHeaderLayout;
